<template>
  <div class="patentSearch">
    <nf-breadcrumb :breadList="[{ name: '知识产权' }, { name: '专利检索' }]"></nf-breadcrumb>
    <div class="wrap">
      <!-- 搜索框 -->
      <div class="searchWrap">
        <div class="bgWrap">
          <el-input placeholder="请输入关键字" v-model="params.kw" class="input-with-select">
            <div @mouseenter="showArea = true" @mouseleave="showArea = false" class="areaFiltration" slot="prepend">
              地域筛选 ▼
            </div>
            <el-button @click="toPatentList" slot="append" icon="el-icon-search"></el-button>
          </el-input>
          <div @mouseenter="showArea = true" @mouseleave="showArea = false" class="areaWrap" v-show="showArea">
            <el-row>
              <el-col :span="5">
                <el-checkbox v-model="checkAll_all" @change="handleCheckAllChange_all">全球</el-checkbox>
              </el-col>
              <el-col :span="19">数据范围</el-col>
            </el-row>
            <el-row v-show="!checkAll_all">
              <el-col :span="5"><el-checkbox v-model="checkAll_one" @change="handleCheckAllChange_one">中国</el-checkbox></el-col>
              <el-col :span="19">
                <el-checkbox-group v-model="checked_one" @change="handleCheckedAreaChange_one">
                  <el-checkbox v-for="area in area_one" :label="area.value" :key="area.value">{{ area.name }}</el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
            <el-row v-show="!checkAll_all">
              <el-col :span="5"><el-checkbox v-model="checkAll_two" @change="handleCheckAllChange_two">主要国家和地区</el-checkbox></el-col>
              <el-col :span="19">
                <el-checkbox-group v-model="checked_two" @change="handleCheckedAreaChange_two">
                  <el-checkbox v-for="area in area_two" :label="area.value" :key="area.value">{{ area.name }}</el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
            <el-row v-show="!checkAll_all">
              <el-col :span="5"
                ><el-checkbox v-model="checkAll_three" @change="handleCheckAllChange_three">其他国家和地区</el-checkbox></el-col
              >
              <el-col :span="19">
                <el-checkbox-group v-model="checked_three" @change="handleCheckedAreaChange_three">
                  <el-checkbox v-for="area in area_three" :label="area.value" :key="area.value">{{ area.name }}</el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <!-- 查询范围 -->
      <el-row class="scopeWrap">
        <el-col :span="24">
          <el-radio-group v-model="params.scope">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="1">标题/摘要</el-radio>
            <el-radio :label="2">申请人</el-radio>
            <el-radio :label="3">发明人</el-radio>
            <el-radio :label="4">代理机构/代理人</el-radio>
            <el-radio :label="5">专利号码</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <!-- 筛选数据 -->
      <div class="tagsWrap">
        <el-tag
          :key="index"
          v-for="(tag, index) in tagsArr"
          closable
          :disable-transitions="false"
          @close="handleClose(tag.type, tag.index, index)"
        >
          {{ tag.msg }}
        </el-tag>
      </div>
      <!-- 数据表格 -->
      <div class="patentTable" v-loading="loading_table">
        <el-row type="flex">
          <el-col :span="3">专利类型</el-col>
          <el-col :span="21">
            <span
              :class="item.isChecked ? 'checkedTag' : ''"
              @click="handleFilter('type', item.name, index)"
              v-for="(item, index) in tableData.type"
              :key="item.id"
              >{{ item.name === '1' ? '发明专利' : item.name === '2' ? '实用新型' : '外观设计' }}({{ item.count }})</span
            >
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="3">申请人</el-col>
          <el-col :span="21">
            <span
              :class="item.isChecked ? 'checkedTag' : ''"
              @click="handleFilter('applicants.name.original.keyword', item.name, index)"
              v-for="(item, index) in tableData.applicants_name"
              :key="item.id"
            >
              {{ item.name }}({{ item.count }})
            </span>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="3">发明人</el-col>
          <el-col :span="21">
            <span
              :class="item.isChecked ? 'checkedTag' : ''"
              @click="handleFilter('inventors.name.original.keyword', item.name, index)"
              v-for="(item, index) in tableData.inventors_name"
              :key="item.id"
            >
              {{ item.name }}({{ item.count }})
            </span>
          </el-col>
        </el-row>
        <el-row v-show="showMore" type="flex">
          <el-col :span="3">代理机构</el-col>
          <el-col :span="21">
            <span
              :class="item.isChecked ? 'checkedTag' : ''"
              @click="handleFilter('agencies.keyword', item.name, index)"
              v-for="(item, index) in tableData.agencies"
              :key="item.id"
            >
              {{ item.name }}({{ item.count }})
            </span>
          </el-col>
        </el-row>
        <el-row v-show="showMore" type="flex">
          <el-col :span="3">代理人</el-col>
          <el-col :span="21">
            <span
              :class="item.isChecked ? 'checkedTag' : ''"
              @click="handleFilter('agents.keyword', item.name, index)"
              v-for="(item, index) in tableData.agents"
              :key="item.id"
            >
              {{ item.name }}({{ item.count }})
            </span>
          </el-col>
        </el-row>
        <el-row v-show="showMore" type="flex">
          <el-col :span="3">所属国/地区</el-col>
          <el-col :span="21">
            <span
              :class="item.isChecked ? 'checkedTag' : ''"
              @click="handleFilter('countries', item.id, index)"
              v-for="(item, index) in tableData.countries"
              :key="item.id"
            >
              {{ item.name }}({{ item.count }})
            </span>
          </el-col>
        </el-row>
        <div class="moreWrap">
          <div class="rail"></div>
          <div v-if="showMore" class="more" @click="showMore = false">
            精简选项 ∧
          </div>
          <div v-else class="more" @click="showMore = true">更多选项 ∨</div>
        </div>
      </div>
      <!-- 专利列表 -->
      <div class="patentListWrap" v-loading="loading">
        <div class="patentTotal">
          本次共搜索出<span> {{ total }} </span>条相关专利
        </div>
        <div class="patentList">
          <div class="patentItem" v-for="item in patentList" :key="item.id">
            <el-row class="patentName">
              <span @click="toPatentDetail(item.id)">{{ item.patent.title.original }}</span>
              <span>{{ item.patent.countries + '/' + ['', '发明', '实用', '外观'][item.patent.type] }}</span></el-row
            >
            <el-row class="numberWrap">
              <el-col :span="2">申请号：</el-col>
              <el-col :span="18">
                {{ item.patent.application_number }}
                <span style="float: right">公开号：{{ item.patent.publication_number }}</span>
              </el-col>
            </el-row>
            <el-row class="dateWrap">
              <el-col :span="2">申请日：</el-col>
              <el-col :span="18">
                {{ item.patent.application_date | dateFormat }}
                <span style="float: right">首次公开日： {{ item.patent.earliest_publication_date | dateFormat }}</span>
              </el-col>
            </el-row>
            <el-row class="proposer">
              <el-col :span="2">申请人：</el-col>
              <el-col :span="22">{{ item.patent.applicants[0].name.original }}</el-col>
            </el-row>
            <el-row class="inventor">
              <el-col :span="2">发明人：</el-col>
              <el-col :span="22">
                <span v-for="(ite, j) in item.patent.inventors" :key="j"
                  >{{ ite.name.original }}
                  <span v-if="j !== item.patent.inventors.length - 1">,</span>
                </span>
              </el-col>
            </el-row>
            <el-row class="digest">
              <el-col :span="2">专利摘要：</el-col>
              <el-col :span="22">{{ item.patent.abstract.original }}</el-col>
            </el-row>
          </div>
        </div>
        <nf-pagination :total="total" @pageChange="pageChange" :currentPage="params.pageNum"></nf-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import nfPagination from '@/component/pagination/index';
import { Loading, Message } from 'element-ui';

export default {
  name: 'patentList',
  data() {
    return {
      showMore: false, // 展示数据项
      showArea: false, // 地区选择框
      loading: true, // 加载状态
      loading_table: true, // 加载状态-表格
      // 查询类型
      navList: [
        { text: '全部', isChecked: true },
        { text: '标题/摘要', isChecked: false },
        { text: '申请人', isChecked: false },
        { text: '发明人', isChecked: false },
        { text: '代理机构/代理人', isChecked: false },
        { text: '专利号码', isChecked: false }
      ],
      // 地区选择
      //-----
      checkAll_all: false,
      checkAll_one: false,
      area_one: [
        { value: 'cn', name: '中国大陆' },
        { value: 'hk', name: '香港' },
        { value: 'mo', name: '澳门' },
        { value: 'tw', name: '台湾' }
      ],
      checked_one: [],
      //-----
      checkAll_two: false,
      area_two: [
        { value: 'ep', name: 'EPO' },
        { value: 'wipo', name: 'WIPO' },
        { value: 'us', name: '美国' },
        { value: 'jp', name: '日本' },
        { value: 'kr', name: '韩国' },
        { value: 'gb', name: '英国' },
        { value: 'fr', name: '法国' },
        { value: 'de', name: '德国' },
        { value: 'ru', name: '俄罗斯' }
      ],
      checked_two: [],
      //-----
      checkAll_three: false,
      area_three: [
        { value: 'at', name: '奥地利' },
        { value: 'au', name: '澳大利亚' },
        { value: 'be', name: '比利时' },
        { value: 'nl', name: '荷兰' },
        { value: 'ca', name: '加拿大' },
        { value: 'es', name: '西班牙' },
        { value: 'mx', name: '墨西哥' }
      ],
      checked_three: [],
      // 查询参数
      params: {
        pageNum: 1,
        pageSize: 10,
        kw: '', // 关键字
        scope: 0, // 查询范围
        type: [1, 2, 3],
        countries: [] // 地区
      },
      // 标签
      tagsArr: [],
      // 表格数据
      tableData: {
        inventors_name: [],
        applicants_name: [],
        agencies: [],
        countries: [],
        type: [],
        agents: []
      },
      patentList: [], // 专利列表
      total: 0 // 专利总数量
    };
  },
  methods: {
    // 切换类型
    handleSwitch(index) {
      this.params.scope = index;
      this.navList.forEach(item => {
        item.isChecked = false;
      });
      this.navList[index].isChecked = true;
    },
    // 地区选择
    handleCheckAllChange_all(val) {
      if (val) {
        this.checked_one = [];
        this.checked_two = [];
        this.checked_three = [];
        this.checkAll_one = false;
        this.checkAll_two = false;
        this.checkAll_three = false;
      }
    },
    handleCheckAllChange_one(val) {
      this.checked_one = val ? ['cn', 'hk', 'mo', 'tw'] : [];
    },
    handleCheckedAreaChange_one(value) {
      const checkedCount = value.length;
      this.checkAll_one = checkedCount === this.area_one.length;
    },
    handleCheckAllChange_two(val) {
      this.checked_two = val ? ['ep', 'wipo', 'us', 'jp', 'kr', 'gb', 'fr', 'de', 'ru'] : [];
    },
    handleCheckedAreaChange_two(value) {
      const checkedCount = value.length;
      this.checkAll_two = checkedCount === this.area_two.length;
    },
    handleCheckAllChange_three(val) {
      this.checked_three = val ? ['at', 'au', 'be', 'nl', 'ca', 'es', 'mx'] : [];
    },
    handleCheckedAreaChange_three(value) {
      const checkedCount = value.length;
      this.checkAll_three = checkedCount === this.area_three.length;
    },
    // 点击搜索
    async toPatentList() {
      this.loading = true;
      this.loading_table = true;
      if (this.checkAll_all) {
        this.params.countries = ['cn', 'hk', 'mo', 'tw'];
      } else {
        this.params.countries = [...this.checked_one, ...this.checked_two, ...this.checked_three];
      }
      await this.getList();
      await this.getTableData();
    },
    // 处理路径参数
    disposePath() {
      const query = this.$route.query;
      if (query.checked_one === '') {
        this.checked_one = [];
      } else {
        this.checked_one = query.checked_one.split(',');
      }
      if (query.checked_two === '') {
        this.checked_two = [];
      } else {
        this.checked_two = query.checked_two.split(',');
      }
      if (query.checked_three === '') {
        this.checked_three = [];
      } else {
        this.checked_three = query.checked_three.split(',');
      }
      this.params.kw = query.kw;
      this.params.scope = Number(query.scope);
      this.params.countries = [...this.checked_one, ...this.checked_two, ...this.checked_three];
      if (this.checked_one.length === 4) {
        this.checkAll_one = true;
      }
      if (this.checked_two.length === 9) {
        this.checkAll_two = true;
      }
      if (this.checked_three.length === 7) {
        this.checkAll_three = true;
      }
      if (query.checked_one.length === 0 && query.checked_two.length === 0 && query.checked_three.length === 0) {
        this.params.countries = ['cn', 'hk', 'mo', 'tw'];
        this.checkAll_all = true;
      } else {
        this.checkAll_all = false;
      }
      this.getTableData();
      this.getList();
    },
    // 获取表格数据
    async getTableData() {
      this.params.aggField = [
        'type',
        'applicants.name.original.keyword',
        'inventors.name.original.keyword',
        'agencies.keyword',
        'countries',
        'agents.keyword'
      ];
      const api = this.$fetchApi.patentList.api;
      this.$fetchData.fetchPost(this.params, api, 'json').then(res=>{
        if (res.code === '200') {
          this.tableData = res.result.rsData[0].aggRS.aggResult;
          this.loading_table = false;
        }
        },(err)=>{
          this.loading_table = false;
        })
        return
    },
    // 获取列表
    async getList(queryCondition) {
      const params_list = JSON.parse(JSON.stringify(this.params));
      delete params_list.aggField;
      if (queryCondition) {
        params_list.queryCondition = queryCondition;
        this.params.queryCondition = queryCondition;
      }
      this.loading = true;
      const api = this.$fetchApi.patentList.api;
      const data = await this.$fetchData.fetchPost(params_list, api, 'json');
      if (data.code === '200') {
        this.patentList = data.result.rsData[0].patents;
        this.total = data.result.rsData[0].rsCount;
        this.loading = false;
      } else {
        this.loading = false;
        Message.error('获取数据失败');
      }
    },
    // 当前页改变
    pageChange(page) {
      this.loading = true;
      this.params.pageNum = page.page_index;
      document.documentElement.scrollTop = 0;
      this.getList();
    },
    // 类型筛选
    handleFilter(type, msg, index) {
      if (type === 'type') {
        msg === '1' ? (msg = '发明专利') : msg === '2' ? (msg = '实用新型') : (msg = '外观设计');
        if (this.tableData.type[index].isChecked) {
          return false;
        }
        this.tableData.type[index].isChecked = true;
      } else if (type === 'applicants.name.original.keyword') {
        if (this.tableData.applicants_name[index].isChecked) {
          return false;
        }
        this.tableData.applicants_name[index].isChecked = true;
      } else if (type === 'inventors.name.original.keyword') {
        if (this.tableData.inventors_name[index].isChecked) {
          return false;
        }
        this.tableData.inventors_name[index].isChecked = true;
      } else if (type === 'agencies.keyword') {
        if (this.tableData.agencies[index].isChecked) {
          return false;
        }
        this.tableData.agencies[index].isChecked = true;
      } else if (type === 'agents.keyword') {
        if (this.tableData.agents[index].isChecked) {
          return false;
        }
        this.tableData.agents[index].isChecked = true;
      } else if (type === 'countries') {
        if (this.tableData.countries[index].isChecked) {
          return false;
        }
        this.tableData.countries[index].isChecked = true;
      }
      this.tagsArr.push({ type, msg, index });
      // 处理标签数据
      this.manageTags();
    },
    // 删除标签
    handleClose(type, tagIndex, index) {
      if (type === 'type') {
        this.tableData.type[tagIndex].isChecked = false;
      } else if (type === 'applicants.name.original.keyword') {
        this.tableData.applicants_name[tagIndex].isChecked = false;
      } else if (type === 'inventors.name.original.keyword') {
        this.tableData.inventors_name[tagIndex].isChecked = false;
      } else if (type === 'agencies.keyword') {
        this.tableData.agencies[tagIndex].isChecked = false;
      } else if (type === 'agents.keyword') {
        this.tableData.agents[tagIndex].isChecked = false;
      } else if (type === 'countries') {
        this.tableData.countries[tagIndex].isChecked = false;
      }
      this.tagsArr.splice(index, 1);
      // 处理标签数据
      this.manageTags();
    },
    // 处理标签数据
    manageTags() {
      const queryCondition = [];
      if (this.tagsArr.length === 1) {
        queryCondition.push({
          field: this.tagsArr[0].type,
          condition: 'in',
          relation: 'and',
          vlist: [this.tagsArr[0].msg]
        });
      } else {
        this.tagsArr.forEach(item1 => {
          let flag = false;
          queryCondition.forEach(item2 => {
            if (item2.field === item1.type) {
              item2.vlist.push(item1.msg);
              flag = true;
              return false;
            }
          });
          if (!flag) {
            queryCondition.push({
              field: item1.type,
              condition: 'in',
              relation: 'and',
              vlist: [item1.msg]
            });
          }
        });
      }
      queryCondition.forEach(item1 => {
        if (item1.field === 'type') {
          item1.vlist.forEach((item2, i) => {
            item2 === '发明专利' ? (item1.vlist[i] = '1') : item2 === '实用新型' ? (item1.vlist[i] = '2') : (item1.vlist[i] = '3');
          });
          return false;
        }
      });
      this.params.pageNum = 1;
      this.getList(queryCondition);
    },
    // 跳转详情
    toPatentDetail(id) {
      // [
      //   { field: 'type', condition: 'in', relation: 'and', vList: ['1'] },
      //   { field: 'applicants.name.original.keyword', condition: 'in', relation: 'and', vList: ['华为技术有限公司'] },
      //   { field: 'inventors.name.original.keyword', condition: 'in', relation: 'and', vList: ['曾清海'] },
      //   { field: 'agencies.keyword', condition: 'in', relation: 'and', vList: ['北京同立钧成知识产权代理有限公司'] },
      //   { field: 'agents.keyword', condition: 'in', relation: 'and', vList: ['刘芳'] },
      //   { field: 'countries', condition: 'in', relation: 'and', vList: ['cn'] }
      // ];
      const routeUrl = this.$router.resolve({
        path: '/patentSearch/patentDetail',
        query: { id }
      });
      window.open(routeUrl.href, '_blank');
    }
  },
  created() {
    this.disposePath();
  },
  filters: {
    dateFormat(v) {
      const value = String(v);
      return Number(value.substring(0, 4)) + '年' + Number(value.substring(4, 6)) + '月' + Number(value.substring(6, 8)) + '日';
    }
  },
  components: { nfBreadcrumb, nfPagination }
};
</script>

<style lang="less" scoped>
.tagsWrap {
  margin-bottom: 10px;
  .el-tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.wrap {
  width: 1200px;
  margin: 0 auto;
  .searchWrap {
    position: relative;
    padding-top: 20px;
    .bgWrap {
      position: relative;
      .areaWrap {
        z-index: 999;
        position: absolute;
        width: 1099px;
        background: #fff;
        left: 0;
        top: 49px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        .el-row {
          padding: 20px;
          /deep/ .el-checkbox__label {
            font-size: 16px;
          }
        }
        .el-row:hover {
          background: #eee;
        }
      }
    }
  }
  /deep/ .el-input-group__prepend {
    padding: 0;
    width: 150px !important;
    box-sizing: border-box;
    background: url('~@/assets/images/property/地球@3x.png') no-repeat 10px center;
  }
  .areaFiltration {
    height: 100%;
    line-height: 48px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 50px;
  }
  .searchWrap /deep/ .el-input-group__prepend {
    width: 120px;
  }
  .searchWrap /deep/ .el-input-group--append,
  .searchWrap /deep/ .el-input__inner {
    height: 50px;
    font-size: 16px;
  }
  .searchWrap /deep/ .el-input-group__append {
    width: 60px;
    text-align: center;
    background: #6188cf;
    border-color: #6188cf;
    font-size: 20px;
    color: #fff;
  }
  .scopeWrap {
    padding: 20px;
    text-align: center;
    /deep/ .el-radio__label {
      font-size: 16px;
    }
    /deep/ .el-radio__input {
      font-size: 16px;
    }
  }
  .patentTable {
    .el-row {
      border-left: 1px solid #ccc;
      &:last-child {
        border-bottom: 1px solid #ccc;
      }
      .el-col {
        border: 1px solid #ccc;
        border-left: 0;
        border-bottom: 0;
        padding: 10px;
        line-height: 30px;
        span {
          padding-right: 40px;
          display: inline-block;
        }
        span.checkedTag {
          color: rgb(62, 76, 202);
        }
        span:hover {
          color: rgb(62, 76, 202);
        }
      }
      .el-col:first-child {
        background: #efefef;
        text-align: center;
      }
    }
    .moreWrap {
      overflow: hidden;
      .rail {
        width: 100%;
        height: 8px;
        background: #ddd;
      }
      .more {
        height: 30px;
        width: 100px;
        float: right;
        background: #ddd;
        text-align: center;
        line-height: 30px;
      }
    }
  }
  .patentListWrap {
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px #ccc;
    margin-top: 50px;
    .patentTotal {
      background: #f1f1f1;
      height: 50px;
      line-height: 50px;
      text-indent: 2em;
      span {
        color: #6e95cd;
      }
    }
    .patentList {
      padding: 0 20px;
      .patentItem {
        border-bottom: 1px dashed #ccc;
        padding: 20px 0;
        .el-row {
          color: #777;
          margin-bottom: 10px;
        }
        .patentName:hover {
          cursor: pointer;
          color: #265ae1;
        }
        .patentName {
          font-size: 20px;
          font-weight: 900;
          border-left: 5px solid #5f8bc9;
          padding-left: 20px;
          margin-bottom: 20px;
          color: #666;
          span:last-child {
            background: #5f8bc9;
            font-size: 14px;
            border-radius: 5px;
            color: #fff;
            padding: 2px;
            font-weight: 400;
            margin-left: 20px;
            vertical-align: middle;
          }
        }
        .digest {
          .el-col:last-child {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
</style>
